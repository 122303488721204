import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';



class Footer extends Component {

  render() {
    return (


<footer class="py-5 bg-dark">
      <div class="container">
      <div class="row">

      { this.props.account
? <img
    className='ml-1 rounded-5'
    width='24'
    height='24'
    src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
  : <span></span>
}

{ this.props.account
  ? <img
    className='ml-1 rounded-5'
    width='24'
    height='24'
    src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
  : <span></span>
}


      <div class="container">
            <p class="m-0 text-center text-white">Copyright &copy; WorldLaw 2020 - WorldLaw.eth</p>
      </div>
      </div>

      </div>

  </footer>


);
}
}

export default Footer;
