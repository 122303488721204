import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Gut2 extends Component {

  render() {
    return (

<div class="container">

{ this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>          
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<small className="text-black">Current Account: <span id="account">{this.props.account}</span></small>

<section class="page-section" id="services1">
      <div class="row">
      <div class="col-lg-12 text-center">
<hr></hr>
        <h2 class="section-heading font-weight-bold text-primary">Purpose</h2>
        <h3 class="section-subheading text-muted">open source code - public use of nodes - Ethereum blockchain</h3>
      </div>
      </div>

      <div class="row text-center">
      <div class="col-md-4">
      <a class="nav-link js-scroll-trigger" href="#more-info">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fab fa-github fa-stack-1x fa-inverse "></i>
          </span>
      </a>
        <h4 class="service-heading">Source code</h4>
            <p class="text-muted">Github is where the public code can be reviewed</p>
      </div>

      <div class="col-md-4">
            <a class="nav-link js-scroll-trigger" href="#more-info">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-bullhorn fa-stack-1x fa-inverse "></i>
          </span>
          </a>

        <h4 class="service-heading">Public blockchain event broadcasting</h4>
            <p class="text-muted">Features announcements</p>
      </div>

      <div class="col-md-4">
          <a class="nav-link js-scroll-trigger" href="#more-info">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fab fa-ethereum fa-stack-1x fa-inverse "></i>
          </span>
          </a>

        <h4 class="service-heading">public Ethereum persistence</h4>
            <p class="text-muted">Encrypt and Upload Content</p>
      </div>
      </div>
  </section>


  <section class="page-section" id="services2">
      <div class="row">
      <div class="col-lg-12 text-center">
  <hr></hr>
        <h2 class="section-heading font-weight-bold text-primary">Operations</h2>
        <h3 class="section-subheading text-muted">Micro-transaction fees - encrypted file storage and retrieval - NodeJS engine</h3>
      </div>
      </div>

      <div class="row text-center">
      <div class="col-md-4">
      <a class="nav-link js-scroll-trigger" href="#more-info">

          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-gas-pump fa-stack-1x fa-inverse "></i>
          </span>
          </a>

        <h4 class="service-heading">Ethereum transaction fuel</h4>
            <p class="text-muted">Small amounts of Ether are required for all blockchain transactions</p>
      </div>

      <div class="col-md-4">
      <a class="nav-link js-scroll-trigger" href="#more-info">

          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-dice-d6 fa-stack-1x fa-inverse "></i>
          </span>
          </a>

        <h4 class="service-heading">Swarm and IPFS</h4>
            <p class="text-muted">Content storage systems</p>
      </div>

      <div class="col-md-4">
      <a class="nav-link js-scroll-trigger" href="#more-info">

          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fab fa-node-js fa-stack-1x fa-inverse "></i>

          </span>
          </a>

        <h4 class="service-heading">NodeJS</h4>
            <p class="text-muted">Open-source, cross-platform JavaScript run-time environment</p>
      </div>
      </div>

  </section>

  <section class="page-section" id="services3">
      <div class="row">
      <div class="col-lg-12 text-center">
  <hr></hr>        
        <h2 class="section-heading font-weight-bold text-primary">Data-Processing</h2>
        <h3 class="section-subheading text-muted">Client-side AES256 encryption - anonymous file upload - courtroom ready 24/7/365</h3>
      </div>
      </div>

      <div class="row text-center">
      <div class="col-md-4">
      <a class="nav-link js-scroll-trigger" href="#more-info">

          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-fingerprint fa-stack-1x fa-inverse "></i>
          </span>
          </a>

        <h4 class="service-heading">AES256 Encryption</h4>
            <p class="text-muted">Advanced Encryption Standard (AES) is a symmetric block cipher used to encrypt and protect confidential information</p>
      </div>

      <div class="col-md-4">
      <a class="nav-link js-scroll-trigger" href="#more-info">

          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-upload fa-stack-1x fa-inverse "></i>
          </span>
          </a>

        <h4 class="service-heading">Secure Upload</h4>
            <p class="text-muted">Encrypted content and form-data is copied to nodes and Ethereum blockchain</p>
      </div>

      <div class="col-md-4">
      <a class="nav-link js-scroll-trigger" href="#more-info">

          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-landmark fa-stack-1x fa-inverse "></i>
          </span>
          </a>

        <h4 class="service-heading">Use in existing court systems</h4>
            <p class="text-muted">Satisfies U.S. Federal Rules of Evidence</p>

      </div>
      </div>
  </section>


  <section class="page-section" id="services4">
      <div class="row">
      <div class="col-lg-12 text-center">
      <hr></hr>
        <h2 class="section-heading font-weight-bold text-primary">System Tools</h2>
        <h3 class="section-subheading text-muted">user reqd - bitcoin to fund wallet - facebook support</h3>
      </div>
      </div>

      <div class="row text-center">
      <div class="col-md-4">
      <a class="nav-link js-scroll-trigger" href="#more-info">

          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fab fa-opera fa-stack-1x fa-inverse "></i>
          </span>
          </a>

        <h4 class="service-heading">Access Web3 with Opera or MetaMask</h4>
            <p class="text-muted">Web3 ready browsers are required to use the WorlLaw protocols features</p>
      </div>

      <div class="col-md-4">
      <a class="nav-link js-scroll-trigger" href="#more-info">

          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fab fa-bitcoin fa-stack-1x fa-inverse "></i>
          </span>
          </a>

        <h4 class="service-heading">Use Bitcoin to pay transaction fees</h4>
            <p class="text-muted">Some transactions required a small fee that is paid in Ether, the currency of the Ethereum blockchain. Using Bitcoin to purchase Ether is one way to get started and use the WorldLaw protocols.</p>
      </div>

      <div class="col-md-4">
      <a class="nav-link js-scroll-trigger" href="#more-info">

          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fab fa-facebook-f fa-stack-1x fa-inverse "></i>
          </span>
          </a>

        <h4 class="service-heading">Facebook</h4>
            <p class="text-muted">Facebook is a social networking website where users can interact with other users.</p>

      </div>
      </div>
  </section>

  </div>
);
}
}

export default Gut2;
