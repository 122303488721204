import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Gut3 extends Component {

  render() {
    return (

<div class="container">


<section class="testimonials text-center bg-light">
    <div class="container">

    <div class="row">

    <div class="col-lg-10 mx-auto">
<p class="mdtextblue"><strong>2020 Web3 WorldLaw White Paper</strong></p>
<h3 class="textdesc b-5">Digital evidence and claim management system using decentralized public node computing and ethereum block-chain</h3>

    <a class="btn btn-primary btn-xl js-scroll-trigger mt-3 mb-5" href="../img/web3worldlaw.pdf" target="_blank" rel="noopener noreferrer">View White Paper</a>
</div>


    <div class="col-lg-4 col-md-4 ">
    <div class="testimonial-item mx-auto mb-5 mb-lg-0">
      
    <img class="img-fluid mb-3" src="../img/p01.png" href="../img/web3worldlaw.pdf" alt="WL-testimonials-1" target="_blank" rel="noopener noreferrer"></img>

    <hr></hr>
<a href="../img/web3worldlaw.pdf" target="_blank" rel="noopener noreferrer" >View Abstract </a>
<hr></hr>

    </div>

    </div>


    <div class="col-lg-4 col-md-4">
    <div class="testimonial-item mx-auto mb-5 mb-lg-0">
    <img class="img-fluid mb-3" src="../img/p11.png" alt="WL-testimonials-2"></img>
   

          <hr></hr>
<a href="../img/web3worldlaw.pdf" target="_blank" rel="noopener noreferrer" >View Executive Summary </a>
<hr></hr>


    </div>
    </div>
    <div class="col-lg-4 col-md-4">
    <div class="testimonial-item mx-auto mb-5 mb-lg-0">
    <img class="img-fluid mb-3" src="../img/p25.png" alt="WL-testimonials-3" ></img>
    

    <hr></hr>
<a href="../img/web3worldlaw.pdf" target="_blank" rel="noopener noreferrer" >Federal Evidence Rules </a>
<hr></hr>


    </div>
    </div>



    </div>
    </div>
</section>


</div>

);
}
}

export default Gut3;
