import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class GutBot extends Component {

  render() {
    return (

<div class="container">



<section class="call-to-action text-black text-center mb-0"  id="executetx">
    <div class="row">
    <div class="col-xl-9 mx-auto">
      <h2 class="mb-2 font-weight-bold text-black"><a href="https://etherscan.io" target="_blank" rel="noopener noreferrer" >Verify Ethereum transactions  &nbsp;<i class="fab fa-ethereum"></i></a></h2>

      { this.props.account
              ? <img
                className='ml-1 rounded-5 mr-2'
                width='24'
                height='24'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5 mr-2'
                width='24'
                height='24'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<div className="text-black mt-1">Current Account: <span id="account">{this.props.account}</span>

</div>

    </div>
    </div>



<div class="social-icons">
<ul class="list-unstyled text-center mb-2">
<li class="list-unstyled-item">
 <a href="#github">
 <i class="fab fa-github "></i>
 </a>
</li>
<li class="list-unstyled-item">
 <a href="#bullhorn">
 <i class="fas fa-bullhorn "></i>
 </a>
</li>
<li class="list-unstyled-item">
 <a href="#ethereum">
 <i class="fab fa-ethereum "></i>
 </a>
</li>
<li class="list-unstyled-item">
 <a href="#gaspump">
 <i class="fas fa-gas-pump "></i>
 </a>
</li>
<li class="list-unstyled-item">
 <a href="#dice">
 <i class="fas fa-dice-d6 "></i>
 </a>
</li>
<li class="list-unstyled-item">
 <a href="#node">
 <i class="fab fa-node-js "></i>
 </a>
</li>
<li class="list-unstyled-item">
 <a href="#fingerprint">
 <i class="fas fa-fingerprint "></i>
 </a>
</li>
<li class="list-unstyled-item">
 <a href="#upload">
 <i class="fas fa-upload "></i>
 </a>
</li>
<li class="list-unstyled-item">
 <a href="#landmark">
 <i class="fas fa-landmark "></i>
 </a>
</li>
<li class="list-unstyled-item">
 <a href="#opera">
 <i class="fab fa-opera "></i>
 </a>
</li>
<li class="list-unstyled-item">
 <a href="#bitcoin">
 <i class="fab fa-bitcoin "></i>
 </a>
</li>
<li class="list-unstyled-item">
 <a href="#facebook">
 <i class="fab fa-facebook-f "></i>
 </a>
</li>
<li class="list-unstyled-item">
 <a href="https://twitter.com/tenfinney">
 <i class="fab fa-twitter "></i>
 </a>
</li>
</ul>
</div>


</section>


</div>


);
}
}

export default GutBot;
